import React from 'react'


import { Button } from '@chakra-ui/react'
import {
  Popover,
  PopoverBody,
  PopoverContent,
  Icon,
  Text,
  Flex,
  PopoverTrigger,
} from '@chakra-ui/react'

import { FaUserCircle } from "react-icons/fa";
import { FaWindowClose } from "react-icons/fa";
import { IoSyncCircleSharp } from "react-icons/io5";
import { UserContext } from '../../Context/UserContext';

export function ButtonLogin() {
  const [open, setOpen] = React.useState(false)
  const { Userlogout } =  React.useContext(UserContext);
  
  return (
    <Popover open={open} onOpenChange={(e) => setOpen(e.open)}>
    <PopoverTrigger asChild>
      <Button size="sm" variant="outline">
        <FaUserCircle />
      </Button>
    </PopoverTrigger>
    <PopoverContent>
      {/* <PopoverArrow /> */}
      {/* <PopoverBody> */}
        <Button size="sm" variant="outline" onClick={Userlogout}>
          <Flex gap='2'>
            <FaWindowClose />
            <Text>SAIR DO SISTEMA</Text>
          </Flex>
        </Button>
        <Button size="sm" variant="outline" onClick={Userlogout}>
          <Flex gap='2'>
            <IoSyncCircleSharp />
            <Text>TROCAR USUARIO</Text>
          </Flex>
        </Button>
      {/* </PopoverBody> */}
    </PopoverContent>
  </Popover>
  );
}

export default ButtonLogin