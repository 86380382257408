import React from 'react';
import { useColorMode, useColorModeValue, useMediaQuery, Button, Tooltip, Text } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';

export const ColorModeSwitcher = (props) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

  return (
    <>
    {isLargerThan800 ? (
    <Tooltip hasArrow label='Clique para alterar o tema' fontSize='12' placement='left-end' mb='4'>
      <Button 
        size='sx'
        // _hover={{ bg: '#1A1A1A' }}
        variant='ghost' 
        onClick={toggleColorMode}>
        <SwitchIcon size='15'/>
      </Button>
    </Tooltip>
    ) : (
      <Button 
        ml='1'
        mr='8'
        size='sm'
        variant='ghost' onClick={toggleColorMode} >
        <SwitchIcon size='15'/>
      </Button>
    )}
    </>
  );
};
